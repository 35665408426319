import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../types/titles';
import { QueryClient } from '@tanstack/react-query';
import { fetchDictionary } from '../../../api/common';
import { useAppDispatch } from '../../../hooks';
import { useLoaderData } from 'react-router-dom';
import { useEffect } from 'react';
import { setDictionary } from '../../../services/dictionaries';
import LegalStatementsLayout from '../../../components/about/legal-statements';

export const legalStatementsLoader = () => async () => {
	return new QueryClient().fetchQuery({
		queryKey: ['legalStatementsLoader'],
		queryFn: () => fetchDictionary('documents'),
		staleTime: 86400
	});
};

const LegalStatements = () => {
	const dispatch = useAppDispatch();

	const data = useLoaderData();

	useEffect(() => {
		dispatch(
			setDictionary(
				//eslint-disable-next-line
				Object.assign(data as any, {
					dictionaryName: 'legalStatements'
				})
			)
		);
	}, []);

	return (
		<>
			<Helmet>
				<title>{TITLES.ABOUT.LEGAL_STATEMENTS}</title>
			</Helmet>
			<LegalStatementsLayout />
		</>
	);
};

export default LegalStatements;

import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../hooks';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { selectCommunityDictionary } from '../../../services/dictionaries';
import { ROUTES } from '../../../types/routes';
import { mapClassnameForMobile } from '../../../utils';
import { CustomButton } from '../../common/buttons';
import HeaderLayout from '../../common/header';
import { CommunityIcons } from '../../common/icons';

const CommunityLayout = () => {
	const communityDictionary = useAppSelector(selectCommunityDictionary);

	const { t } = useTranslation();

	const navigate = useCustomNavigate();

	const handleBackClickButton = () => {
		navigate(ROUTES.ABOUT.INDEX);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent aboutComponent'}>
				<HeaderLayout isAboutHeader noIcon isOnlyHeader={'settings_community'} />
				<div className={mapClassnameForMobile('walletLayout')}>
					<CustomButton
						isBack={true}
						onClick={handleBackClickButton}
						className={'backButton'}
					/>
					<div className='commonContainer'>
						<div className='commonItem'>
							<CommunityIcons.ShareIcon />
							<h1 className='commonText'>
								{t('community_shareApp')}
							</h1>
						</div>
					</div>
					<div className='commonContainer'>
						{communityDictionary?.dictionary &&
							communityDictionary?.dictionary?.links?.map(
								(
									obj: {
										[key: string]: string;
									},
									idx: number
								) => {
									const objEntries = Object.entries(obj);
									return (
										<div
											key={idx}
											onClick={() =>
												window.open(
													objEntries[0][1],
													'_blank',
													'rel=noopener noreferrer'
												)
											}
											className='commonItem'
										>
											<CommunityIcons.SocialMediaIcon
												socialMedia={objEntries[0][0].toLowerCase()}
											/>
											<h1 className='commonText'>
												{objEntries[0][0]}
											</h1>
										</div>
									);
								}
							)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CommunityLayout;

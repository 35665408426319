import { useRef, useState } from 'react';
import HeaderLayout from '../common/header';
import { ExchangeInput } from '../common/inputs';
import { CustomButton } from '../common/buttons';
import { useTranslation } from 'react-i18next';
import { SwapIcon } from '../common/icons';
import { BottomNavigationContainer } from '../common/navigation';

const ExchangeLayout = () => {
	const [fromAmount, setFromAmount] = useState<string>('');
	const [toAmount, setToAmount] = useState<string>('');

	const buttonRef = useRef(null);
	const { t } = useTranslation();

	const [isInputError, setIsInputError] = useState<boolean>(false);

	const handleFromChange = async (amount: string) => {
		setFromAmount(amount);
	};

	const handleToChange = async (amount: string) => {
		setToAmount(amount);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout />
				<div className="walletLayout">
					<ExchangeInput
						asset={{
							code: 'USDT',
							group: 'Crypto',
							name: 'USDT',
							precision: 2,
							available: 10000.00,
						}}
						isFrom={true}
						isInputError={isInputError}
						setIsInputError={setIsInputError}
						available={10000}
						handleAvailableClick={(amount) => setFromAmount(amount)}
						amount={fromAmount}
						setAmount={handleFromChange}
						fontSize={fromAmount.length > 8 ? '18px' : '24px'}
					/>
					<CustomButton
						onClick={() => console.log('WIP')}
						isDisabled={Number(fromAmount) <= 0}
						isSwapButton={true}
						className={'exchangeSwapContainer'}
					>
						<SwapIcon className={'exchangeSwapIcon'} />
					</CustomButton>
					<ExchangeInput
						asset={{
							code: 'LTC',
							group: 'Crypto',
							name: 'LTC',
							precision: 2,
							available: 10000.00,
						}}
						isFrom={false}
						isInputError={isInputError}
						setIsInputError={setIsInputError}
						available={10}
						amount={toAmount}
						setAmount={handleToChange}
						fontSize={toAmount.length > 8 ? '18px' : '24px'}
						rate={'1 USDT ~ 88,49 LTC'}
					/>
					<CustomButton
						buttonRef={buttonRef}
						text={t('exchangeButtonText') as string}
						type={'submit'}
						onClick={() => console.log('WIP')}
						className={'baseButtonContainer'}
					/>
				</div>

				<BottomNavigationContainer />
			</div>
		</div>
	);
};

export default ExchangeLayout;

import { useTranslation } from 'react-i18next';
import HeaderLayout from '../../common/header';
import { CustomInput } from '../../common/inputs';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { CustomButton } from '../../common/buttons';
import { useLoginWithoutTwoFAMutation } from '../../../data/mutations/login/login';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { APIError } from '../../common/error';
import { selectLoginData } from '../../../services/auth';
import { mapClassnameForMobile } from '../../../utils';
import { selectIsTelegram } from '../../../services/root';

const LoginLayout = () => {
	const isTelegram = useAppSelector(selectIsTelegram);
	const loginData = useAppSelector(selectLoginData);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const [email, setEmail] = useState<string>('');
	const [isEmailRequired, setIsEmailRequired] = useState<boolean>(false);

	const [password, setPassword] = useState<string>('');
	const [isPasswordRequired, setIsPasswordRequired] = useState<boolean>(false);

	const navigate = useCustomNavigate();

	const { login, isError, error, isPending } = useLoginWithoutTwoFAMutation(navigate);

	const buttonRef = useRef(null);
	const [fakeError, setFakeError] = useState<boolean>(false);

	useEffect(() => {
		if (loginData) {
			setEmail(loginData.login);
			setIsEmailRequired(loginData.login.length > 0);

			setPassword(loginData.password);
			setIsPasswordRequired(loginData.password.length > 0);
		}
	}, []);

	useEffect(() => {
		if (isError) {
			setFakeError(isError);
		}
	}, [isError]);

	const handleEmailChange = (e: ChangeEvent<HTMLInputElement>): void => {
		setEmail(e.target.value);
		setFakeError(false);
		e.target.value.length > 0 ? setIsEmailRequired(true) : setIsEmailRequired(false);
	};

	const handleEmailClearClick = () => {
		setEmail('');
		setFakeError(false);
		setIsEmailRequired(false);
	};

	const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>): void => {
		setPassword(e.target.value);
		setFakeError(false);
		e.target.value.length > 0
			? setIsPasswordRequired(true)
			: setIsPasswordRequired(false);
	};

	const handlePasswordClearClick = () => {
		setPassword('');
		setFakeError(false);
		setIsPasswordRequired(false);
	};

	const handleLogin = async () => {
		await login({
			login: email,
			password: password,
			isTelegram: isTelegram,
			dispatch: dispatch,
		});
	};

	const handleBackClickButton = () => {
		navigate(ROUTES.AUTH.INDEX);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout noIcon isOnlyHeader={'quickLogin_identify'} />
				<div className={mapClassnameForMobile('walletLayout ', true)}>
					<CustomButton
						isBack={true}
						onClick={handleBackClickButton}
						className={'backButton'}
					/>
					<CustomInput
						isTabDisable={true}
						isError={fakeError}
						isRequired={isEmailRequired}
						type={'email'}
						id={'email'}
						label={t("login_email")}
						value={email}
						onChange={handleEmailChange}
						className={'input'}
						onClearClick={handleEmailClearClick}
						inputPropsRef={buttonRef}
					/>
					<CustomInput
						isTabDisable={true}
						isError={fakeError}
						isRequired={isPasswordRequired}
						type={'password'}
						id={'password'}
						label={t('login_password')}
						value={password}
						onChange={handlePasswordChange}
						className={'input passwordInput'}
						onClearClick={handlePasswordClearClick}
						inputPropsRef={buttonRef}
						isPassword={true}
					/>
					<a
						style={{
							width: '100%',
						}}
						onClick={() => navigate(ROUTES.AUTH.PASSWORD_RECOVERY)}
					>
						<h1 className={'forgotPasswordText'}>
							{t('login_forgotPassword')}
						</h1>
					</a>
					<APIError isError={fakeError} error={error} />
					<CustomButton
						buttonRef={buttonRef}
						isLoading={isPending}
						text={t('login_continue') as string}
						type={'submit'}
						onClick={handleLogin}
						className={'startButton noMargin'}
						isDisabled={
							password.length < 6 ||
							!new RegExp(
								/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu
							).test(email)
						}
					/>
				</div>
			</div>
		</div>
	);
};

export default LoginLayout;

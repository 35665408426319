import { useTranslation } from 'react-i18next';
import { TWalletModalItem } from '../../../types/types';
import { CurrencyChev, PositiveChev } from '../../common/icons';
import { useAppSelector } from '../../../hooks';
import {
	selectBasePrecision,
	selectTotalAmount
} from '../../../services/wallet';
import { useEffect, useState } from 'react';

interface WalletTotalCardProps {
	isBalanceHidden: boolean;
	isLoading: boolean;
	handleModalOpen: () => void;
	globalAsset: TWalletModalItem;
	isVerified: boolean;
}

const WalletTotalCard = ({
	isBalanceHidden,
	handleModalOpen,
	globalAsset,
	isVerified
}: WalletTotalCardProps) => {
	const { t } = useTranslation();

	const basePrecision = useAppSelector(selectBasePrecision);
	const totalAmount = useAppSelector(selectTotalAmount);

	const [todayDelta, setTodayDelta] = useState<number>(0);
	const [todayPercent, setTodayPercent] = useState<number>(0);

	useEffect(() => {
		if (isVerified) {
			if (totalAmount?.totalAmount && totalAmount?.estimatedBalance00h) {
				if (
					totalAmount?.totalAmount > 0 &&
					totalAmount?.estimatedBalance00h
				) {
					setTodayDelta(
						totalAmount?.totalAmount -
							totalAmount?.estimatedBalance00h
					);
					setTodayPercent(
						(totalAmount?.totalAmount * 100) /
							totalAmount.estimatedBalance00h -
							100
					);
				}
			}
		}
	}, [totalAmount?.totalAmount, totalAmount?.estimatedBalance00h]);

	return (
		<div className='balanceContainer'>
			{isVerified ? (
				<>
					{totalAmount?.totalAmount && basePrecision != 0 ? (
						<div className='mainBalanceContainer'>
							<h1 className='mainBalanceText'>
								{isBalanceHidden
									? '******'
									: totalAmount.totalAmount
											.toFixed(basePrecision)
											.toLocaleString()}
							</h1>
							<div
								onClick={handleModalOpen}
								className='mainBalanceSelectContainer'
							>
								<h1 className='currencyText'>
									{globalAsset.code}
								</h1>
								<CurrencyChev className='currencyTextChev' />
							</div>
						</div>
					) : (
						<div className='mainBalanceContainerLoading'></div>
					)}
				</>
			) : (
				<div className='mainBalanceContainer'>
					<h1 className='mainBalanceText'>
						{isBalanceHidden
							? '******'
							: Number(0).toFixed(basePrecision).toLocaleString()}
					</h1>
					<div
						onClick={handleModalOpen}
						className='mainBalanceSelectContainer'
					>
						<h1 className='currencyText'>{globalAsset.code}</h1>
						<CurrencyChev className='currencyTextChev' />
					</div>
				</div>
			)}

			{isVerified && (
				<>
					{!totalAmount?.totalAmount &&
					!totalAmount?.estimatedBalance00h ? (
						<div className='secondaryBalanceContainerLoading'></div>
					) : (
						<div className='secondaryBalanceContainer'>
							<h1 className='secondaryBalanceText'>
								{t('wallet_today')}
							</h1>
							<div className='secondaryBalanceAnalyticsContainer'>
								{!isBalanceHidden && (
									<PositiveChev
										isPositive={todayDelta > 0}
										className='analyticsChev'
									/>
								)}
								<h1
									className={
										todayDelta > 0
											? 'secondaryBalanceAnalyticsText'
											: 'secondaryNegativeBalanceAnalyticsText'
									}
								>
									{isBalanceHidden
										? '******'
										: todayPercent.toFixed(2) +
										  '% ' +
										  '(' +
										  todayDelta.toFixed(basePrecision) +
										  ' ' +
										  globalAsset.code +
										  ')'}
								</h1>
							</div>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default WalletTotalCard;

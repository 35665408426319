import { useTranslation } from 'react-i18next';
import { F2XMainIcon, HiddenBalanceIcon } from '../common/icons';
import { MainNavigationContainer } from '../common/navigation';
import { useState } from 'react';
import { WalletItemList } from '../common/lists';
import { TWalletModalItem } from '../../types/types';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
	selectGlobalAsset,
	setGlobalAsset,
	setIsWalletPageInited,
	setTotalAmount
} from '../../services/wallet';
import {
	WalletConvertModal,
	WalletModal,
	WalletSearchModal
} from '../common/modals';
import { useGetUserRests } from '../../data/mutations/wallet';
import WalletTotalCard from './total-card';
import WalletConvertContainer from './search';
import { selectIsVerified } from '../../services/user';
import WalletConfirmIdentity from './confirm-identity';

const WalletLayout = () => {
	const dispatch = useAppDispatch();
	const globalAsset = useAppSelector(selectGlobalAsset);
	const isVerified = useAppSelector(selectIsVerified);
	const { t } = useTranslation();

	const { isLoading } = useGetUserRests(isVerified);

	const [isBalanceHidden, setIsBalanceHidden] = useState<boolean>(false);
	const [isLowBalanceHidden, setIsLowBalanceHidden] =
		useState<boolean>(false);

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isWalletSearchModalOpen, setIsWalletSearchModalOpen] =
		useState<boolean>(false);
	const [isWalletConvertModalOpen, setIsWalletConvertModalOpen] =
		useState<boolean>(false);

	const handleItemClick = (i: TWalletModalItem) => {
		dispatch(setIsWalletPageInited(false));
		dispatch(setTotalAmount(0));
		setIsModalOpen(!isModalOpen);
		dispatch(setGlobalAsset(i));
	};

	const handleModalOpen = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleWalletSearchModalOpen = () => {
		setIsWalletSearchModalOpen(!isWalletSearchModalOpen);
	};

	const handleWalletConvertModalOpen = () => {
		setIsWalletConvertModalOpen(!isWalletConvertModalOpen);
	};

	return (
		<div className='walletLayout'>
			<WalletSearchModal
				isBalanceHidden={isBalanceHidden}
				globalAsset={globalAsset}
				isModalOpen={isWalletSearchModalOpen}
				handleIsModalOpen={handleWalletSearchModalOpen}
			/>
			<WalletConvertModal
				globalAsset={globalAsset}
				isModalOpen={isWalletConvertModalOpen}
				handleIsModalOpen={handleWalletConvertModalOpen}
			/>
			<WalletModal
				isModalOpen={isModalOpen}
				handleIsModalOpen={handleModalOpen}
				handleItemClick={handleItemClick}
				currentAsset={globalAsset}
			/>

			<div
				onClick={() => setIsBalanceHidden(!isBalanceHidden)}
				className='estimatedBalanceContainer'
			>
				<h1 className='estimatedBalanceText'>
					{t('wallet_estimatedBalance')}
				</h1>
				<HiddenBalanceIcon
					isHidden={isBalanceHidden}
					className='estimatedBalanceIcon'
				/>
			</div>

			<WalletTotalCard
				isVerified={isVerified}
				handleModalOpen={handleModalOpen}
				isLoading={isLoading}
				isBalanceHidden={isBalanceHidden}
				globalAsset={globalAsset}
			/>

			<MainNavigationContainer
				isVerified={isVerified}
				isLoading={isLoading}
			/>

			<F2XMainIcon />

			<WalletConvertContainer
				isVerified={isVerified}
				onWalletConvertModalClick={handleWalletConvertModalOpen}
				onModalClick={handleWalletSearchModalOpen}
				isLoading={isLoading}
				isLowBalanceHidden={isLowBalanceHidden}
				setIsLowBalanceHidden={setIsLowBalanceHidden}
			/>

			{isVerified ? (
				<WalletItemList
					isLoading={isLoading}
					isBalanceHidden={isBalanceHidden}
					globalAsset={globalAsset}
					isLowBalanceHidden={isLowBalanceHidden}
				/>
			) : (
				<WalletConfirmIdentity />
			)}
		</div>
	);
};

export default WalletLayout;

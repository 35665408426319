export const TITLES = {
	ROOT: {
		INDEX: 'Wallet',
	},
	EXCHANGE: {
		INDEX: 'Exchange',
	},
	MARKET: {
		INDEX: 'Market',
	},
	AUTH: {
		INDEX: 'Authentication',
		LOGIN: 'Login',
		TWOFA: 'TwoFA | Login',
		SELECT_TWOFA: 'Select TwoFa | Login',
		OTP: 'OTP | Login',
		SETUP: 'Setup TwoFA | Login',
		SECRET_KEY: 'Secret Key | Login',
		SENT: 'Sent | Login',
		IMPERSONATE: 'Impersonate | Login',
		RECOVERY: 'Recovery | Login',
		CONFIRM_EMAIL: 'Confirm Code | Login',
		NEW_PASSWORD: 'New Password | Login'
	},
	ABOUT: {
		INDEX: 'About',
		HELP: 'Help & Support',
		LEGAL_STATEMENTS: 'Legal Statements',
		COMMUNITY: 'Community'
	}
};

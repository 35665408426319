import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { CustomButton, TwoFAButton } from '../../common/buttons';
import { useState } from 'react';
import { useSendOtpCodeMutation } from '../../../data/mutations/login/login';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectIdentityTypes, selectState } from '../../../services/auth';
import { APIError } from '../../common/error';
import { mapClassnameForMobile } from '../../../utils';

const LoginSelectTwoFaLayout = () => {
	const dispatch = useAppDispatch();
	const state = useAppSelector(selectState);
	const identityTypes = useAppSelector(selectIdentityTypes);
	const { t } = useTranslation();

	const navigate = useCustomNavigate();

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const { sendOtpCode, isError, error } = useSendOtpCodeMutation(navigate);

	const handleBackClick = () => {
		navigate(ROUTES.AUTH.LOGIN);
	};

	const handleOTPSelect = (otpType: string) => {
		setIsModalOpen(!isModalOpen);
		if (otpType === 'GoogleAuthenticator') {
			navigate(ROUTES.AUTH.TWO_FA);
		} else {
			sendOtpCode({
				identityType: otpType,
				state: state as string,
				navigate: navigate,
				dispatch: dispatch
			});
		}
	};

	const mapTextByIdentityValue = (identity: string) => {
		if (identity.toLowerCase() === 'email') {
			return t('twoFactorAuthMethods_email') as string;
		}
		if (identity.toLowerCase() === 'phone') {
			return t('twoFactorAuthMethods_phone') as string;
		}
		if (identity.toLowerCase() === 'googleauthenticator') {
			return t('twoFactorAuthMethods_app') as string;
		}
		return '';
	};

	return (
		<>
			<div>
				<div className={'component'}>
					<div
						style={{
							backgroundColor: '#F6F6F8'
						}}
						className={'innerComponent'}
					>
						<div
							className={mapClassnameForMobile(
								'walletLayout authMainContainer'
							)}
						>
							<CustomButton
								isBack={true}
								onClick={handleBackClick}
								className={'backButton'}
							/>
							<h1 className={'welcomeText'}>
								{t('twoFactorAuthMethods_title')}
							</h1>
							<h1 className={'confirmCodeText'}>
								{t('twoFactorAuthMethods_subtitle')}
							</h1>
							{identityTypes.map((identity) => (
								<TwoFAButton
									onClick={() =>
										handleOTPSelect(identity.key)
									}
									text={mapTextByIdentityValue(identity.key)}
									iconType={identity.key}
								/>
							))}
							<APIError isError={isError} error={error} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default LoginSelectTwoFaLayout;

import { useEffect, useMemo } from 'react';
import { MarketAssetItem, WalletItem } from './items';
import { TAsset, TMarketAsset, TWalletModalItem } from '../../../types/types';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
	selectCommissionAccountRests,
	selectGlobalAsset,
	selectIsEmpty,
	selectMainAccountRests,
	selectMarginAccountsRests,
	selectSpotAccountsRests,
	selectWalletConvertAssets,
	setIsEmpty
} from '../../../services/wallet';
import { useTranslation } from 'react-i18next';
import { LeverageIcon, TransferSpotIcon } from '../icons';
import WalletEmptyList from '../../wallet/empty-wallet';

interface WalletItemListProps {
	isLowBalanceHidden: boolean;
	isLoading?: boolean;
	isBalanceHidden: boolean;
	globalAsset: TWalletModalItem;
	searchValue?: string;
	isWalletSearchModal?: boolean;
	isWalletConvertModal?: boolean;
	handleSelectedAmountChange?: (
		i: TAsset,
		isSelected: boolean,
		idx: number
	) => void;
	selected?: boolean[];
}

const WalletItemList = ({
	isLowBalanceHidden,
	isLoading,
	globalAsset,
	isBalanceHidden,
	searchValue,
	isWalletSearchModal,
	isWalletConvertModal,
	handleSelectedAmountChange,
	selected
}: WalletItemListProps) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const mainCurrencies = useAppSelector(selectMainAccountRests);
	const commissionCurrencies = useAppSelector(selectCommissionAccountRests);
	const spotCurrencies = useAppSelector(selectSpotAccountsRests);
	const marginCurrencies = useAppSelector(selectMarginAccountsRests);
	const isEmpty = useAppSelector(selectIsEmpty);

	const walletConvertAssets = useAppSelector(selectWalletConvertAssets);

	const minHideValue = mainCurrencies?.baseLowBalance as number;

	function filterCurrenciesByMinHideValue(
		currencies: TAsset[] | undefined,
		isLowBalanceHidden: boolean,
		searchValue: string | undefined
	) {
		if (currencies) {
			if (searchValue) {
				return currencies.filter(
					(currency) =>
						currency.code.toLowerCase().includes(searchValue) ||
						currency.name.toLowerCase().includes(searchValue)
				);
			}
			if (isLowBalanceHidden) {
				return currencies.filter((currency) =>
					currency?.baseAvailable
						? currency.baseAvailable > minHideValue
						: currency.available > minHideValue
				);
			} else {
				return currencies;
			}
		}
	}

	const visibleMainCurrencies = useMemo(
		() =>
			filterCurrenciesByMinHideValue(
				mainCurrencies?.assets,
				isLowBalanceHidden,
				searchValue
			),
		[mainCurrencies, isLowBalanceHidden, searchValue]
	);

	const visibleSpotCurrencies = useMemo(
		() =>
			filterCurrenciesByMinHideValue(
				spotCurrencies?.assets,
				isLowBalanceHidden,
				searchValue
			),
		[spotCurrencies, isLowBalanceHidden, searchValue]
	);
	const visibleMarginCurrencies = useMemo(
		() =>
			filterCurrenciesByMinHideValue(
				marginCurrencies?.assets,
				isLowBalanceHidden,
				searchValue
			),
		[marginCurrencies, isLowBalanceHidden, searchValue]
	);

	const visibleCommissionCurrencies = useMemo(
		() =>
			filterCurrenciesByMinHideValue(
				commissionCurrencies?.assets,
				isLowBalanceHidden,
				searchValue
			),
		[commissionCurrencies, isLowBalanceHidden, searchValue]
	);

	useEffect(() => {
		if (!isLowBalanceHidden) {
			const localIsEmpty =
				(visibleCommissionCurrencies?.length ?? 0) +
					(visibleMainCurrencies?.length ?? 0) +
					(visibleMarginCurrencies?.length ?? 0) +
					(visibleSpotCurrencies?.length ?? 0) ===
				0;
			dispatch(setIsEmpty(localIsEmpty));
		}
	}, [
		visibleCommissionCurrencies,
		visibleMainCurrencies,
		visibleMarginCurrencies,
		visibleSpotCurrencies,
		walletConvertAssets
	]);

	const loadingCards = ['1', '2', '3', '4'];

	if (isWalletSearchModal && isEmpty) {
		return <WalletEmptyList isWalletSearchModal />;
	}

	if (isWalletConvertModal) {
		return (
			<div className='assetsWalletSearchContainer'>
				{!walletConvertAssets
					? loadingCards.map((card) => (
							<WalletItem
								isLoading
								key={card}
								idx={Number(card)}
							/>
					  ))
					: walletConvertAssets?.assets.map(
							(currency, idx: number) => (
								<WalletItem
									isChecked={(selected as boolean[])[idx]}
									handleSelectedAmountChange={
										handleSelectedAmountChange
									}
									basePrecision={
										mainCurrencies?.basePrecision
									}
									isBalanceHidden={isBalanceHidden}
									baseAsset={globalAsset.code}
									key={idx}
									idx={idx}
									asset={currency}
								/>
							)
					  )}
			</div>
		);
	}

	if (!isLoading && !isLowBalanceHidden && isEmpty && !isWalletSearchModal) {
		return <WalletEmptyList />;
	}

	return (
		<div
			style={
				isWalletSearchModal
					? {}
					: {
							marginBottom: '64px'
					  }
			}
			className={
				isWalletSearchModal
					? 'assetsWalletSearchContainer'
					: 'assetsContainer'
			}
		>
			{!visibleMainCurrencies
				? loadingCards.map((card) => (
						<WalletItem isLoading key={card} idx={Number(card)} />
				  ))
				: visibleMainCurrencies?.map((currency, idx: number) => (
						<WalletItem
							basePrecision={mainCurrencies?.basePrecision}
							isBalanceHidden={isBalanceHidden}
							baseAsset={globalAsset.code}
							key={idx}
							idx={idx}
							asset={currency}
						/>
				  ))}

			{visibleSpotCurrencies && visibleSpotCurrencies.length > 0 && (
				<>
					<div className='innerAssetsContainer'>
						<h1 className='innerAssetsHeaderText'>
							{t('wallet_spot')}
						</h1>
						<div className='innerAssetsTransferContainer'>
							<TransferSpotIcon />
							<h1 className='innerAssetsTransferText'>
								{t('wallet_transfer')}
							</h1>
						</div>
					</div>
					{visibleSpotCurrencies?.map((currency, idx: number) => (
						<WalletItem
							basePrecision={mainCurrencies?.basePrecision}
							isBalanceHidden={isBalanceHidden}
							baseAsset={globalAsset.code}
							key={idx}
							idx={idx}
							asset={currency}
						/>
					))}
				</>
			)}

			{visibleMarginCurrencies && !marginCurrencies?.isEmpty && (
				<>
					<div className='innerAssetsContainer'>
						<h1 className='innerAssetsHeaderText'>
							{t('wallet_leverage')}
						</h1>
						<LeverageIcon />
					</div>
					{visibleMarginCurrencies?.map((currency, idx: number) => (
						<>
							{currency.available > 0 ? (
								<WalletItem
									basePrecision={
										mainCurrencies?.basePrecision
									}
									isBalanceHidden={isBalanceHidden}
									baseAsset={globalAsset.code}
									key={idx}
									idx={idx}
									asset={currency}
								/>
							) : (
								''
							)}
						</>
					))}
				</>
			)}

			{visibleCommissionCurrencies &&
				visibleCommissionCurrencies.length > 0 && (
					<>
						<div className='innerAssetsContainer'>
							<h1 className='innerAssetsHeaderText'>
								{t('wallet_commission')}
							</h1>
						</div>
						{visibleCommissionCurrencies?.map(
							(currency, idx: number) => (
								<WalletItem
									basePrecision={
										mainCurrencies?.basePrecision
									}
									isBalanceHidden={isBalanceHidden}
									baseAsset={globalAsset.code}
									key={idx}
									idx={idx}
									asset={currency}
								/>
							)
						)}
					</>
				)}
		</div>
	);
};

interface MarketAssetsList {
	assets: TMarketAsset[];
}

const MarketAssetsList = ({ assets }: MarketAssetsList) => {
	const globalAsset = useAppSelector(selectGlobalAsset);

	return (
		<div
			style={{
				marginBottom: '64px'
			}}
			className='marketAssetsContainer'
		>
			{assets.map((asset, idx: number) => (
				<MarketAssetItem
					globalAssetCode={globalAsset?.code}
					key={idx}
					idx={idx}
					asset={asset}
				/>
			))}
		</div>
	);
};

export { WalletItemList, MarketAssetsList };

export const ROUTES = {
	WALLET: {
		INDEX: '/:locale/wallet'
	},
	EXCHANGE: {
		INDEX: '/:locale/exchange'
	},
	MARKET: {
		INDEX: '/:locale/market'
	},
	ROOT: {
		INDEX: '/'
	},
	INACTIVE: {
		INDEX: '/:locale/inactive'
	},
	AUTH: {
		INDEX: '/:locale/auth',
		LOGIN: '/:locale/auth/login',
		TWO_FA: '/:locale/auth/login/2fa',
		OTP: '/:locale/auth/login/otp',
		SETUP_TWOFA: '/:locale/auth/login/setupTwoFa',
		SECRET_KEY: '/:locale/auth/login/secretKey',
		CONFIRM_TWOFA: '/:locale/auth/login/confirmTwoFa',
		SELECT_TWOFA: '/:locale/auth/login/selectTwoFa',
		IMPERSONATE: '/:locale/auth/login/impersonate',
		PASSWORD_RECOVERY: '/:locale/auth/login/recovery',
		CONFIRM_EMAIL: '/:locale/auth/login/recovery/confirm',
		NEW_PASSWORD: '/:locale/auth/login/recovery/password'
	},
	ABOUT: {
		INDEX: '/:locale/about',
		HELP: '/:locale/about/help',
		LEGAL_STATEMENTS: '/:locale/about/statements',
		COMMUNITY: '/:locale/about/community'
	}
};

import { createSlice } from '@reduxjs/toolkit';
import { TRootState } from '../../store';

export const selectIsVerified = (state: TRootState) => state.user.isVerified;

interface IRootInitialState {
	isVerified: boolean;
}

const initialState: IRootInitialState = {
	isVerified: localStorage.getItem('isVerified')
		? Boolean(localStorage.getItem('isVerified'))
		: false
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setIsVerified(state, action) {
			localStorage.setItem('isVerified', action.payload);
			state.isVerified = action.payload;
		}
	}
});

export const { setIsVerified } = userSlice.actions;

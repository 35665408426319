import { useEffect, useState } from 'react';
import {
	TAsset,
	TMarketAsset,
	TWalletModalItem
} from '../../../../types/types';
import { AssetIcon, FavouriteIcon, PercentChangeIcon } from '../../icons';
import { CustomCheckbox } from '../../checkboxes';

interface WalletItemProps {
	asset?: TAsset;
	idx: number;
	isLoading?: boolean;
	baseAsset?: string;
	isBalanceHidden?: boolean;
	basePrecision?: number;
	handleSelectedAmountChange?: (
		i: TAsset,
		isSelected: boolean,
		idx: number
	) => void;
	isChecked?: boolean;
}

const WalletItem = ({
	asset,
	idx,
	isLoading,
	baseAsset,
	isBalanceHidden,
	basePrecision,
	handleSelectedAmountChange,
	isChecked
}: WalletItemProps) => {
	return (
		<div key={idx} className='assetItem'>
			{handleSelectedAmountChange && (
				<CustomCheckbox
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleSelectedAmountChange(
							asset as TAsset,
							e.target.checked,
							idx
						)
					}
					key={asset?.code}
					checked={isChecked}
				/>
			)}
			{isLoading ? (
				<div className='assetIconLoading'></div>
			) : (
				<AssetIcon
					className='assetIcon'
					group={asset?.group as string}
					assetName={asset?.code as string}
				/>
			)}
			<div className='innerAssetItemHeader'>
				{isLoading ? (
					<div className='innerAssetHeaderTextLoading'></div>
				) : (
					<h1 className='innerAssetHeaderText'>{asset?.code}</h1>
				)}
				{isLoading ? (
					<div className='innerAssetBottomTextLoading'></div>
				) : (
					<h1 className='innerAssetBottomText'>{asset?.name}</h1>
				)}
			</div>
			<div className='innerAssetItemAmount'>
				<h1 className='innerAssetItemAmountText'>
					{isBalanceHidden
						? '******'
						: asset?.code === baseAsset
						? asset?.baseAvailable
								?.toFixed(asset.precision)
								.toLocaleString()
						: asset?.available
								?.toFixed(asset.precision)
								.toLocaleString()}
				</h1>
				{asset?.code != baseAsset && (
					<h1 className='innerAssetItemAmountUSDText'>
						{isBalanceHidden
							? '******'
							: asset?.baseAvailable
									?.toFixed(basePrecision)
									.toLocaleString()}{' '}
						{baseAsset}
					</h1>
				)}
			</div>
		</div>
	);
};

interface MarketAssetItemProps {
	asset: TMarketAsset;
	idx: number;
	globalAssetCode: string;
}

const MarketAssetItem = ({
	asset,
	idx,
	globalAssetCode
}: MarketAssetItemProps) => {
	const [isFavourite, setIsFavourite] = useState<boolean>(false);

	const [isPositivePercent, setIsPositivePercent] = useState<boolean>(false);

	useEffect(() => {
		setIsPositivePercent(asset.percentChange > 0);
	}, []);

	return (
		<div key={idx} className='marketAssetItem'>
			<FavouriteIcon
				className=''
				isActive={isFavourite}
				onClick={() => setIsFavourite(!isFavourite)}
			/>
			<AssetIcon
				className='assetIcon'
				group={asset.group}
				assetName={asset.name}
			/>
			<div className='marketAssetItemNameContainer'>
				<h1 className='upperMarketItemText'>{asset.name}</h1>
				<h1 className='bottomMarketItemText'>{asset.fullName}</h1>
			</div>
			<div className='marketAssetItemRateContainer'>
				<h1 className='upperMarketItemText'>
					{asset.rate.toLocaleString()} {globalAssetCode}
				</h1>
				<div className='rateChevContainer'>
					<PercentChangeIcon
						isPositive={isPositivePercent}
						className=''
					/>
					<h1
						className={
							isPositivePercent
								? 'positivePercent'
								: 'negativePercent'
						}
					>
						{asset.percentChange.toLocaleString().replace('-', '')}%
					</h1>
				</div>
			</div>
		</div>
	);
};

interface WalletModalItemProps {
	asset: TWalletModalItem;
	idx: number;
	onClick: (i: TWalletModalItem) => void;
}

const WalletModalItem = ({ asset, idx, onClick }: WalletModalItemProps) => {
	return (
		<div onClick={() => onClick(asset)} key={idx} className='assetItem'>
			<AssetIcon
				className='assetIcon'
				group={asset.code === 'USDT' ? 'Crypto' : 'Fiat'}
				assetName={asset.code}
			/>
			<div className='innerAssetItemHeader'>
				<h1 className='innerAssetHeaderText'>{asset?.code}</h1>
				<h1 className='innerAssetBottomText'>{asset?.name}</h1>
			</div>
		</div>
	);
};

export { WalletItem, MarketAssetItem, WalletModalItem };

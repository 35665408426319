import { useTranslation } from 'react-i18next';
import { AccountIcon, F2XIcon, HistoryIcon, SupportIcon } from '../icons';
import { useLocation } from 'react-router-dom';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { memo } from 'react';
import { mapTranslationKeyHeaderByName } from '../../../utils';

interface HeaderProps {
	isAboutHeader?: boolean;
	isOnlyHeader?: string;
	noIcon?: boolean;
	isSupportIcon?: boolean;
	//eslint-disable-next-line
	style?: any;
}

const HeaderLayout = memo(function HeaderLayout({
	isOnlyHeader,
	noIcon,
	isSupportIcon,
	style,
	isAboutHeader
}: HeaderProps) {
	const { t } = useTranslation();

	const location = useLocation();

	const navigate = useCustomNavigate();

	if (isSupportIcon) {
		return (
			<div style={style} className='headerLayout endAlignment'>
				<div className='headerNavContainer'>
					<SupportIcon
						onClick={() => navigate(ROUTES.ABOUT.INDEX)}
						className='headerNavIcon'
					/>
				</div>
			</div>
		);
	}

	return (
		<div className={isAboutHeader ? 'aboutHeaderLayout' : 'headerLayout'}>
			<div className='headerLogoContainer'>
				{!noIcon && <F2XIcon className='f2xIcon' />}

				<h1 className='headerMainText'>
					{t(
						isOnlyHeader
							? isOnlyHeader
							: mapTranslationKeyHeaderByName(
									location.pathname.split('/')[2]
							  )
					)}
				</h1>
			</div>
			{!isOnlyHeader && (
				<div className='headerNavContainer'>
					<HistoryIcon className='headerNavIcon' />
					<AccountIcon className='headerNavIcon' />
				</div>
			)}
		</div>
	);
});

export default HeaderLayout;

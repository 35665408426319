import { createSlice } from '@reduxjs/toolkit';
import {
	TAccountInfo,
	TAllAccountsRests,
	TAsset,
	TTotalAmount,
	TWalletModalItem
} from '../../types/types';
import { TRootState } from '../../store';

export const sortRests = (rests: TAsset[], isMargin?: boolean) => {
	return rests.sort((firstElem: TAsset, secondElem: TAsset) => {
		if (isMargin) {
			if (Number(firstElem.baseEquity) > Number(secondElem?.baseEquity)) {
				return -1;
			} else if (
				Number(firstElem.baseEquity) < Number(secondElem?.baseEquity)
			) {
				return 1;
			}
			return 0;
		}
		if (
			firstElem.baseAvailable + firstElem.baseReserved >
			secondElem.baseAvailable + secondElem.baseReserved
		) {
			return -1;
		} else if (
			firstElem.baseAvailable + firstElem.baseReserved <
			secondElem.baseAvailable + secondElem.baseReserved
		) {
			return 1;
		}
		return 0;
	});
};

const mergeTwoRests = (
	initRest: TAsset[],
	targetRest: {
		assets: TAsset[];
		selected: boolean[];
	},
	baseLowBalance: number
) => {
	initRest.forEach((initElement) => {
		if (initElement.baseAvailable < baseLowBalance) {
			let isFound = false;
			targetRest.assets.map((targetElement) => {
				if (initElement.code === targetElement.code) {
					targetElement.available += initElement.available;
					targetElement.baseAvailable += initElement.baseAvailable;
					targetElement.baseAvailable00h +=
						initElement.baseAvailable00h;
					targetElement.baseReserved += initElement.baseReserved;
					targetElement.baseReserved00h +=
						initElement.baseReserved00h;
					isFound = true;
					return;
				}
			});
			if (!isFound) {
				targetRest.assets.push(initElement);
			}
		}
	});
	targetRest.assets = sortRests(targetRest.assets);
	targetRest.selected = targetRest.selected.concat(
		targetRest.assets.map(() => {
			return false;
		})
	);
	return targetRest;
};

const calculateTotalAmount = (state: IWalletState, assets: TAsset[]) => {
	const totalAmount = assets.reduce((currentSum: number, asset: TAsset) => {
		return (
			currentSum +
			Number(asset.baseEquity != undefined ? asset.baseEquity : 0) +
			asset.baseAvailable +
			asset.baseReserved
		);
	}, 0);
	const estimatedBalance00h = assets.reduce(
		(currentSum: number, asset: TAsset) => {
			return (
				currentSum +
				Number(
					asset.baseEquity00h != undefined ? asset.baseEquity00h : 0
				) +
				asset.baseAvailable00h +
				asset.baseReserved00h
			);
		},
		0
	);
	if (state.totalAmount) {
		state.totalAmount.totalAmount += totalAmount;
		state.totalAmount.estimatedBalance00h += estimatedBalance00h;
	} else {
		state.totalAmount = {
			totalAmount: totalAmount,
			estimatedBalance00h: estimatedBalance00h
		};
	}
};

export const selectGlobalAsset = (state: TRootState) =>
	state.wallet.globalAsset;

export const selectMainAccountRests = (state: TRootState) =>
	state.wallet.accountsRests.main;

export const selectCommissionAccountRests = (state: TRootState) =>
	state.wallet.accountsRests.commission;

export const selectSpotAccountsRests = (state: TRootState) =>
	state.wallet.accountsRests.spots;

export const selectMarginAccountsRests = (state: TRootState) =>
	state.wallet.accountsRests.margins;

export const selectTotalAmount = (state: TRootState) =>
	state.wallet.totalAmount;

export const selectIsWalletPageInited = (state: TRootState) =>
	state.wallet.isWalletPageInited;

export const selectBasePrecision = (state: TRootState) =>
	state.wallet.basePrecision;

export const selectWalletConvertAssets = (state: TRootState) =>
	state.wallet.walletConvertAssets;

export const selectBaseLowBalance = (state: TRootState) =>
	state.wallet.baseLowBalance;

export const selectIsEmpty = (state: TRootState) => state.wallet.isEmpty;

interface IWalletState {
	accountsInfo: TAccountInfo[] | undefined;
	accountsRests: TAllAccountsRests;
	globalAsset: TWalletModalItem;
	totalAmount: TTotalAmount | undefined;
	isWalletPageInited: boolean;
	walletConvertAssets: {
		assets: TAsset[];
		selected: boolean[];
	};
	basePrecision: number;
	isEmpty: boolean;
	baseLowBalance: number;
}

const initialState: IWalletState = {
	isWalletPageInited: false,
	basePrecision: 0,
	baseLowBalance: 0,
	isEmpty: false,
	totalAmount: undefined,
	accountsInfo: undefined,
	walletConvertAssets: {
		assets: [],
		selected: []
	},
	accountsRests: {
		main: undefined,
		margins: undefined,
		spots: undefined,
		commission: undefined
	},
	globalAsset: localStorage.getItem('globalAsset')
		? JSON.parse(localStorage.getItem('globalAsset') as string)
		: {
				code: 'USDT',
				name: 'Tether'
		  }
};

export const walletSlice = createSlice({
	name: 'wallet',
	initialState,
	reducers: {
		clearRests(state) {
			state.accountsRests = {
				main: undefined,
				margins: undefined,
				spots: undefined,
				commission: undefined
			};
			state.walletConvertAssets = {
				assets: [],
				selected: []
			};
			state.totalAmount = undefined;
		},
		setAccountsInfo(state, action) {
			state.accountsInfo = action.payload;
		},
		setGlobalAsset(state, action) {
			state.globalAsset = action.payload;
			localStorage.setItem('globalAsset', JSON.stringify(action.payload));
		},
		setMainRests(state, action) {
			state.accountsRests.main = action.payload;
			if (action.payload.assets.length > 0) {
				calculateTotalAmount(state, action.payload.assets);

				state.walletConvertAssets = mergeTwoRests(
					action.payload.assets,
					state.walletConvertAssets,
					action.payload.baseLowBalance
				);
				if (state.accountsRests.main) {
					state.accountsRests.main.assets = sortRests(
						action.payload.assets
					);
				}
			}
			state.baseLowBalance = action.payload.baseLowBalance;
			state.basePrecision = action.payload.basePrecision;
			state.isWalletPageInited = true;
			localStorage.setItem('totalAmount', String(state.totalAmount));
		},
		setIsWalletPageInited(state, action) {
			state.isWalletPageInited = action.payload;
		},
		setCommissionRests(state, action) {
			state.accountsRests.commission = action.payload;
			if (action.payload.assets.length > 0) {
				calculateTotalAmount(state, action.payload.assets);

				if (state.accountsRests.commission) {
					state.accountsRests.commission.assets = sortRests(
						action.payload.assets
					);
				}
			}
			localStorage.setItem('totalAmount', String(state.totalAmount));
		},
		setSpotRests(state, action) {
			if (
				action.payload.assets.length === 0 &&
				state.accountsRests.spots === undefined
			) {
				state.accountsRests.spots = {
					baseLowBalance: action.payload.baseLowBalance,
					basePrecision: action.payload.basePrecision,
					assets: []
				};
				return;
			}

			if (
				action.payload.assets.length > 0 &&
				state.accountsRests.spots === undefined
			) {
				state.accountsRests.spots = {
					baseLowBalance: action.payload.baseLowBalance,
					basePrecision: action.payload.basePrecision,
					isEmpty: false,
					assets: sortRests(action.payload.assets)
				};

				state.walletConvertAssets = mergeTwoRests(
					action.payload.assets,
					state.walletConvertAssets,
					action.payload.baseLowBalance
				);
				calculateTotalAmount(state, action.payload.assets);
				return;
			}

			if (action.payload.assets.length > 0 && state.accountsRests.spots) {
				action.payload.assets.forEach((asset: TAsset) => {
					let isFound = false;
					state.accountsRests.spots?.assets.map(
						(stateAsset: TAsset) => {
							if (stateAsset.code === asset.code) {
								isFound = true;
								stateAsset.available += asset.available;
								stateAsset.baseAvailable += asset.baseAvailable;
								stateAsset.baseAvailable00h +=
									asset.baseAvailable00h;
								stateAsset.baseReserved += asset.baseReserved;
								stateAsset.baseReserved00h +=
									asset.baseReserved00h;
								return;
							}
						}
					);
					if (!isFound) {
						state.accountsRests.spots?.assets.push(asset);
					}
				});
				state.accountsRests.spots.isEmpty = false;
				state.accountsRests.spots.assets = sortRests(
					state.accountsRests.spots.assets
				);
				state.walletConvertAssets = mergeTwoRests(
					action.payload.assets,
					state.walletConvertAssets,
					action.payload.baseLowBalance
				);
				calculateTotalAmount(state, action.payload.assets);
			}
		},
		setMarginRests(state, action) {
			state.accountsRests.margins = action.payload;
			if (action.payload.assets.length > 0) {
				if (state.accountsRests.margins) {
					state.accountsRests.margins.assets = sortRests(
						action.payload.assets,
						true
					);
				}
				calculateTotalAmount(state, action.payload.assets);
			}
		},
		setTotalAmount(state, action) {
			state.totalAmount = action.payload;
		},
		selectAll(state, action) {
			state.walletConvertAssets.selected =
				state.walletConvertAssets.selected.map(() => {
					return action.payload;
				});
		},
		selectOne(state, action) {
			state.walletConvertAssets.selected[action.payload.idx] =
				action.payload.checked;
		},
		clearSelected(state) {
			state.walletConvertAssets.selected =
				state.walletConvertAssets.selected.map(() => {
					return false;
				});
		},
		setIsEmpty(state, action) {
			state.isEmpty = action.payload;
		}
	}
});

export const {
	setAccountsInfo,
	setGlobalAsset,
	setMainRests,
	setCommissionRests,
	setSpotRests,
	setMarginRests,
	setIsWalletPageInited,
	setTotalAmount,
	clearRests,
	selectAll,
	selectOne,
	clearSelected,
	setIsEmpty
} = walletSlice.actions;

import { useEffect, useState } from 'react';
import { usePinInput } from 'react-pin-input-hook';
import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { PinInput } from '../../common/inputs';
import { CustomButton } from '../../common/buttons';
import { useLoginWithTwoFAMutation } from '../../../data/mutations/login/login';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectIsEnablingTwoFa, selectLoginData, selectSecretKey } from '../../../services/auth';
import { APIError } from '../../common/error';
import { mapClassnameForMobile } from '../../../utils';
import { selectIsTelegram } from '../../../services/root';

const LoginTwoFactorLayout = () => {
	const isEnablingTwoFa = useAppSelector(selectIsEnablingTwoFa);
	const secretKey = useAppSelector(selectSecretKey);
	const isTelegram = useAppSelector(selectIsTelegram);
	const loginData = useAppSelector(selectLoginData);
	const dispatch = useAppDispatch();
	const [fakeError, setFakeError] = useState<boolean>(false);

	const [values, setValues] = useState<string[]>(['', '', '', '', '', '']);
	const { fields } = usePinInput({
		values,
		onChange: (values: string[]) => {
			setValues(values);
			setFakeError(false);
		},
		onComplete: (values: string) => {
			if (values.length == 6) {
				login({
					login: loginData?.login as string,
					password: loginData?.password as string,
					twoFA: {
						code: values,
						state: loginData?.state as string,
						identityType: 'GoogleAuthenticator'
					},
					isTelegram: isTelegram,
					dispatch: dispatch,
					isEnablingTwoFa: isEnablingTwoFa,
					secretKey: secretKey,
				});
			}
		},
		type: 'numeric',
		placeholder: ''
	});

	const navigate = useCustomNavigate();

	const { isError, error, login, isPending } =
		useLoginWithTwoFAMutation(navigate);

	const { t } = useTranslation();

	useEffect(() => {
		if (isError) {
			setFakeError(isError);
			setValues(['', '', '', '', '', '']);
		}
	}, [isError]);

	const handleBackClickButton = () => {
		navigate(ROUTES.AUTH.LOGIN);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<div
					className={mapClassnameForMobile(
						'walletLayout authMainContainer'
					)}
				>
					<CustomButton
						isBack={true}
						onClick={handleBackClickButton}
						className={'backButton'}
					/>
					<h1 className={'confirmEmailText'}>
						{t('twoFactorAuthCode_title')}
					</h1>
					<h1 className={'confirmCodeText'}>
						{t('twoFactorAuthCode_pleaseEnter6DigitCode')}
					</h1>
					<div className={'codeInputBlock'}>
						<PinInput
							isLoading={isPending}
							isError={fakeError}
							fields={fields}
						/>
					</div>
					<APIError isError={fakeError} error={error} />
				</div>
			</div>
		</div>
	);
};

export default LoginTwoFactorLayout;

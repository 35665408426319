import { Navigate, Outlet, useLoaderData } from 'react-router-dom';
import { ROUTES } from '../../types/routes';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
	selectLocale,
	setAppSettings,
	setHealth,
	setIsTelegram
} from '../../services/root';
import { useTelegram } from '../../hooks/useTelegram';
import { useEffect } from 'react';
import { setDictionary } from '../../services/dictionaries';

const ProtectedRoute = () => {
	const locale = useAppSelector(selectLocale);

	const isAuthenticated = useAppSelector(
		(state) => state.authentication.isAuthenticated
	);

	if (!isAuthenticated) {
		return <Navigate to={ROUTES.AUTH.INDEX.replace(':locale', locale)} />;
	}

	return <Outlet />;
};

const CommonRoute = () => {
	const dispatch = useAppDispatch();

	const { isTelegram } = useTelegram();

	useEffect(() => {
		dispatch(setIsTelegram(isTelegram));
	}, []);

	return <Outlet />;
};

const GlobalRoute = () => {
	const dispatch = useAppDispatch();
	const data = useLoaderData();

	useEffect(() => {
		dispatch(
			setHealth({
				//eslint-disable-next-line
				timeoutServer: (data as any)?.healthData?.timeoutServer,
				//eslint-disable-next-line
				attemptsNumber: (data as any)?.healthData?.attemptsNumber
			})
		);

		dispatch(
			setDictionary(
				//eslint-disable-next-line
				Object.assign((data as any)?.assetIconsData, {
					dictionaryName: 'assetIcons'
				})
			)
		);
		//eslint-disable-next-line
		dispatch(setAppSettings((data as any)?.appSettingsData));
	}, []);

	return <Outlet />;
};

export { ProtectedRoute, CommonRoute, GlobalRoute };

import { useTranslation } from 'react-i18next';
import { CustomButton } from '../../common/buttons';
import { WhaleIcon } from '../../common/icons';

interface Props {
	isWalletSearchModal?: boolean;
}

const WalletEmptyList = ({isWalletSearchModal}: Props) => {
	const { t } = useTranslation();

	const handleSubmit = () => {
		console.log('WIP');
	};

	if(isWalletSearchModal) {
		return (
			<div className='assetsWalletSearchContainer'>
				<WhaleIcon className='' />
				<h1 className='walletSearchModalNotFoundText'>
					{t('general_noResultsFound')}
				</h1>
			</div>
		);
	}

	return (
		<div className='walletConfirmIdentityContainer'>
			<WhaleIcon className='walletConfirmIdentityWhale' />
			<h1 className='walletConfirmIdentityHeader'>
				{t('wallet_getYourFirstAsset')}
			</h1>
			<h1 className='walletConfirmIdentityBottom'>
				{t('wallet_youDontHaveAnyAssets')}
			</h1>
			<CustomButton
				className='walletConfirmIdentityButton'
				onClick={handleSubmit}
				text={t('wallet_makeFirstDeposit') as string}
			/>
		</div>
	);
};

export default WalletEmptyList;

import { BASE_URL, setDefaultHeaders } from '..';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_ROUTES } from '../../types/api.routes';
import { TLoginPayload } from '../../types/types';
import { createSlice } from '@reduxjs/toolkit';
import { TRootState } from '../../store';

const baseUrl = BASE_URL;

export const selectLoginData = (state: TRootState) =>
	state.authentication.loginData;

export const selectIdentityTypes = (state: TRootState) =>
	state.authentication.identityTypes;

export const selectSelectedIdentityType = (state: TRootState) =>
	state.authentication.selectedIdentityType;

export const selectState = (state: TRootState) =>
	state.authentication.loginData?.state;

export const selectInputLength = (state: TRootState) =>
	state.authentication.inputLength;

export const selectIsAuthenticated = (state: TRootState) =>
	state.authentication.isAuthenticated;

export const selectAccessToken = (state: TRootState) =>
	state.authentication.accessToken;

export const selectSecretKey = (state: TRootState) =>
	state.authentication.secretKey;

export const selectQrCodePath = (state: TRootState) => 
	state.authentication.qrCodePath;


export const selectIsEnablingTwoFa = (state: TRootState) =>
	state.authentication.isEnablingTwoFa;

interface IAuthenticationState {
	isAuthenticated: boolean | null;
	accessToken: string | null;
	loginData:
		| {
				login: string;
				password: string;
				state: string;
		  }
		| undefined;
	inputLength: number;
	identityTypes: { key: string; value: string }[];
	selectedIdentityType: string;
	[key: string]: unknown;
	secretKey: string;
	qrCodePath: string;
	isEnablingTwoFa: boolean;
}

const initialState: IAuthenticationState = {
	secretKey: '',
	isAuthenticated:
		localStorage.getItem('isAuthenticated') != null
			? Boolean(localStorage.getItem('isAuthenticated'))
			: null,
	accessToken:
		localStorage.getItem('accessToken') != null
			? localStorage.getItem('accessToken')
			: '',
	loginData: undefined,
	inputLength: 6,
	identityTypes: [],
	selectedIdentityType: '',
	qrCodePath: '',
	isEnablingTwoFa: false
};

export const authenticationSlice = createSlice({
	name: 'authentication',
	initialState,
	reducers: {
		setLoginData: (state, action) => {
			state.loginData = action.payload;
		},
		clearLoginData: (state) => {
			state.login = '';
			state.password = '';
			state.state = '';
			state.loginData = undefined;
			sessionStorage.removeItem('temporaryAccessToken');
			state.isEnablingTwoFa = false;
			state.secretKey = '';
			state.qrCodePath = '';
		},
		setIdentityTypes: (state, action) => {
			state.identityTypes = action.payload;
		},
		setSelectedIdentityType: (state, action) => {
			state.selectedIdentityType = action.payload;
		},
		setInputLength: (state, action) => {
			state.inputLength = action.payload;
		},
		authenticate: (state, action) => {
			state.accessToken = action.payload;
			state.isAuthenticated = true;
			localStorage.setItem('isAuthenticated', 'true');
			localStorage.setItem('accessToken', action.payload);
		},
		logout: (state) => {
			state.accessToken = '';
			state.isAuthenticated = false;
			localStorage.removeItem('accessToken');
			localStorage.removeItem('isAuthenticated');
		},
		setSecretKey: (state, action) => {
			state.secretKey = action.payload.secretKey;
			state.qrCodePath = action.payload.qrCodePath;
		},
		setIsEnablingTwoFa: (state, action) => {
			state.isEnablingTwoFa = action.payload;
		}
	}
});

export const authenticationAPI = createApi({
	reducerPath: 'authenticationAPI',
	baseQuery: fetchBaseQuery({
		baseUrl: baseUrl,
		prepareHeaders: (headers) => {
			return setDefaultHeaders(headers);
		}
	}),
	endpoints: (builder) => ({
		sendLoginData: builder.mutation({
			query: (data: TLoginPayload) => ({
				url: API_ROUTES.LOGIN.URL,
				method: API_ROUTES.LOGIN.METHOD,
				body: data
			})
		})
	})
});

export const {
	authenticate,
	logout,
	setIdentityTypes,
	setInputLength,
	setSelectedIdentityType,
	setLoginData,
	clearLoginData,
	setSecretKey,
	setIsEnablingTwoFa
} = authenticationSlice.actions;
export const { useSendLoginDataMutation } = authenticationAPI;

import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { mapClassnameForMobile } from '../../../utils';
import { CustomButton } from '../../common/buttons';
import HeaderLayout from '../../common/header';
import { DocumentIcon, HelpIcons, RightChev } from '../../common/icons';
import { selectHelpDictionary } from '../../../services/dictionaries';
import { useAppSelector } from '../../../hooks';

const HelpLayout = () => {
	const helpDictionary = useAppSelector(selectHelpDictionary);

	const { t } = useTranslation();

	const navigate = useCustomNavigate();

	const handleBackClickButton = () => {
		navigate(ROUTES.ABOUT.INDEX);
	};

	return (
		<div className={'component'}>
			<div
				style={{
					height: 'auto'
				}}
				className={'innerComponent aboutComponent'}
			>
				<HeaderLayout
					isAboutHeader
					noIcon
					isOnlyHeader={'settings_helpAndSupport'}
				/>
				<div
					style={{
						marginBottom: '8px'
					}}
					className={mapClassnameForMobile('walletLayout')}
				>
					<CustomButton
						isBack={true}
						onClick={handleBackClickButton}
						className={'backButton'}
					/>
					<div className='commonContainer'>
						<div className='commonItem'>
							<HelpIcons.ChatSupportIcon />
							<h1 className='commonText'>
								{t('support_onlineChat')}
							</h1>
							<RightChev />
						</div>
						<div className='commonItem'>
							<HelpIcons.ContactUsIcon />
							<h1 className='commonText'>
								{t('support_contactUs')}
							</h1>
							<RightChev />
						</div>
					</div>
					<div className='commonContainer'>
						{helpDictionary.dictionary &&
							helpDictionary?.dictionary?.map(
								(
									obj: {
										[key: string]: string;
									},
									idx: number
								) => {
									const objEntries = Object.entries(obj);
									return (
										<div
											key={idx}
											onClick={() =>
												window.open(
													objEntries[0][1],
													'_blank',
													'rel=noopener noreferrer'
												)
											}
											className='commonItem'
										>
											<DocumentIcon />
											<h1 className='commonText'>
												{objEntries[0][0]}
											</h1>
										</div>
									);
								}
							)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default HelpLayout;

import { createSlice } from '@reduxjs/toolkit';
import { TAppSettings, TLocale, TTheme } from '../../types/types';
import { TRootState } from '../../store';

export const selectLocale = (state: TRootState) => state.root.locale;

export const selectTheme = (state: TRootState) => state.root.theme;

export const selectCounter = (state: TRootState) => state.root.counter;

export const selectIsResendCodeActive = (state: TRootState) =>
	state.root.isResendCodeActive;

export const selectIsTelegram = (state: TRootState) => state.root.isTelegram;

export const selectTimeoutServer = (state: TRootState) =>
	state.root.timeoutServer;

export const selectAttemptsNumber = (state: TRootState) =>
	state.root.attemptsNumber;

export const selectAppSettings = (state: TRootState) => state.root.appSettings;

interface IRootInitialState {
	locale: TLocale;
	theme: TTheme;
	counter: number;
	isResendCodeActive: boolean;
	isTelegram: boolean;
	timeoutServer: number;
	attemptsNumber: number;
	appSettings: TAppSettings | undefined;
}

const initialState: IRootInitialState = {
	locale: window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en',
	theme: 'light',
	counter: 60,
	isResendCodeActive: false,
	isTelegram: false,
	timeoutServer: 0,
	attemptsNumber: 0,
	appSettings: undefined
};

export const rootSlice = createSlice({
	name: 'root',
	initialState,
	reducers: {
		setLocale(state, action) {
			state.locale = action.payload;
		},
		setTheme(state, action) {
			state.theme = action.payload;
		},
		reduceCounter(state) {
			state.counter = state.counter - 1;
		},
		updateCounter(state, action) {
			state.counter = action.payload;
		},
		setIsResendCodeActive(state, action) {
			state.isResendCodeActive = action.payload;
		},
		setIsTelegram(state, action) {
			state.isTelegram = action.payload;
		},
		setHealth(state, action) {
			state.timeoutServer = action.payload?.timeoutServer;
			state.attemptsNumber = action.payload?.attemptsNumber;
		},
		setAppSettings(state, action) {
			state.appSettings = action.payload;
		}
	}
});

export const {
	setLocale,
	setTheme,
	reduceCounter,
	updateCounter,
	setIsResendCodeActive,
	setIsTelegram,
	setHealth,
	setAppSettings
} = rootSlice.actions;

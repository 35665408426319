import { createBrowserRouter } from 'react-router-dom';
import { ROUTES } from './types/routes';
import ErrorLayout from './components/error';
import Exchange from './routes/exchange/exchange.route';
import InActive from './routes/inactive.route';
import Market from './routes/market/market.route';
import Auth from './routes/auth/auth.route';
import LoginLayout from './components/auth/login';
import LoginTwoFactor from './routes/auth/login.twofactor.route';
import LoginOTP from './routes/auth/login.otp.route';
import LoginSelectTwoFa from './routes/auth/login.selectwofa.route';
import { CommonRoute, GlobalRoute, ProtectedRoute } from './components/route';
import About, { aboutLoader } from './routes/about/about.route';
import LegalStatements, {
	legalStatementsLoader
} from './routes/about/legal-statements/legal-statements.route';
import Community, {
	communityLoader
} from './routes/about/community/community.route';
import Help, { helpLoader } from './routes/about/help/help.route';
import { globalRouteLoader } from './utils/loaders';
import Wallet from './routes/wallet/wallet.route';
import LoginSetupTwoFa from './routes/auth/login.setuptwofa.route';
import LoginSecretKey, {
	secretKeyLoader
} from './routes/auth/login.secretkey.route';

export const router = createBrowserRouter([
	{
		path: ROUTES.ROOT.INDEX,
		errorElement: <ErrorLayout />
	},
	{
		element: <GlobalRoute />,
		errorElement: <ErrorLayout />,
		loader: globalRouteLoader,
		children: [
			{
				element: <ProtectedRoute />,
				children: [
					{
						path: ROUTES.WALLET.INDEX,
						element: <Wallet />,
						errorElement: <ErrorLayout />
					},
					{
						path: ROUTES.EXCHANGE.INDEX,
						element: <Exchange />,
						errorElement: <ErrorLayout />
					},
					{
						path: ROUTES.MARKET.INDEX,
						element: <Market />,
						errorElement: <ErrorLayout />
					}
				]
			},
			{
				element: <CommonRoute />,
				children: [
					{
						path: ROUTES.AUTH.INDEX,
						element: <Auth />,
						errorElement: <ErrorLayout />
					},
					{
						path: ROUTES.AUTH.LOGIN,
						element: <LoginLayout />,
						errorElement: <ErrorLayout />
					},
					{
						path: ROUTES.AUTH.TWO_FA,
						element: <LoginTwoFactor />,
						errorElement: <ErrorLayout />
					},
					{
						path: ROUTES.AUTH.OTP,
						element: <LoginOTP />,
						errorElement: <ErrorLayout />
					},
					{
						path: ROUTES.AUTH.SETUP_TWOFA,
						element: <LoginSetupTwoFa />,
						errorElement: <ErrorLayout />
					},
					{
						path: ROUTES.AUTH.SECRET_KEY,
						element: <LoginSecretKey />,
						loader: secretKeyLoader,
						errorElement: <ErrorLayout />
					},
					{
						path: ROUTES.AUTH.SELECT_TWOFA,
						element: <LoginSelectTwoFa />,
						errorElement: <ErrorLayout />
					},
					{
						path: ROUTES.INACTIVE.INDEX,
						element: <InActive />,
						errorElement: <ErrorLayout />
					},
					{
						path: ROUTES.ABOUT.INDEX,
						element: <About />,
						loader: aboutLoader,
						errorElement: <ErrorLayout />
					},
					{
						path: ROUTES.ABOUT.LEGAL_STATEMENTS,
						element: <LegalStatements />,
						loader: legalStatementsLoader,
						errorElement: <ErrorLayout />
					},
					{
						path: ROUTES.ABOUT.HELP,
						element: <Help />,
						loader: helpLoader,
						errorElement: <ErrorLayout />
					},
					{
						path: ROUTES.ABOUT.COMMUNITY,
						element: <Community />,
						loader: communityLoader,
						errorElement: <ErrorLayout />
					}
				]
			}
		]
	}
]);
